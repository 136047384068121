import counterpartyEmployeesTypes from "@/store/modules/counterparty/modules/counterpartyEmployees/types";
import counterpartyEmployeeTypes from "@/store/modules/counterpartyEmployee/types";
import foreignOrganizationCounterpartyTypes from "@/store/modules/counterparty/modules/foreignOrganizationCounterparty/types";
import legalEntityCounterpartyTypes from "@/store/modules/counterparty/modules/legalEntityCounterparty/types";
import legalPersonCounterpartyTypes from "@/store/modules/counterparty/modules/legalPersonCounterparty/types";
import physicalPersonCounterpartyTypes from "@/store/modules/counterparty/modules/physicalPersonCounterparty/types";
import counterpartyTypes from "@/store/modules/counterparty/types";
import contactInformationStepTypes from "@/store/modules/masterAccountAccess/modules/contactInformationStep/types";
import documentsStepTypes from "@/store/modules/masterAccountAccess/modules/documentsStep/types";
import selectCounterpartyStepTypes from "@/store/modules/masterAccountAccess/modules/selectCounterpartyStep/types";
import finalStepTypes from "@/store/modules/masterAccountAccess/modules/finalStep/types";
import masterAccountAccessTypes from "@/store/modules/masterAccountAccess/types";
import officeGeneralTypes from "@/store/modules/office/modules/general/types";
import officeEmployeesTypes from "@/store/modules/office/modules/officeEmployees/types";
import officeEmployeeTypes from "@/store/modules/officeEmployee/types";
import officeTypes from "@/store/modules/office/types";
import { resolveNestedNamespace } from "@/utils/vuexModules";
import accountTypes from "@/store/modules/account/types";
import accountGeneralTypes from "@/store/modules/account/modules/general/types";
import accountProfilesTypes from "@/store/modules/account/modules/profiles/types";
import counterpartyEmployeeGeneralTypes from "@/store/modules/counterpartyEmployee/modules/general/types";
import counterpartyEmployeeTrustsTypes from "@/store/modules/counterpartyEmployee/modules/trusts/types";
import counterpartyEmployeeTrustTypes from "@/store/modules/counterpartyEmployee/modules/trust/types";
import counterpartyMasterAccountTypes from "@/store/modules/counterparty/modules/counterpartyMasterAccount/types";
import counterpartyMasterAccountEmailModule from "@/store/modules/counterparty/modules/counterpartyMasterAccount/modules/email";
import accountGeneralEmailModule from "@/store/modules/account/modules/general/modules/email";
import signApplicationStepTypes from "@/store/modules/masterAccountAccess/modules/signApplicationStep/types";
import accountGeneralPasswordModule from "@/store/modules/account/modules/general/modules/password";
import officeEmployeeGeneralTypes from "@/store/modules/officeEmployee/modules/general/types";
import counterpartyEmployeeProfileTypes from "@/store/modules/counterpartyEmployee/modules/profile/types";
import officeEmployeeProfileTypes from "@/store/modules/officeEmployee/modules/profile/types";
import breadcrumbsTypes from "@/store/modules/breadcrumbs/types";
import barBreadcrumbsTypes from "@/store/bar/modules/breadcrumbs/types";
import loanBreadcrumbsTypes from "@/store/modules/breadcrumbs/types";
import masterAccountAccessApplicationDetailsTypes from "@/store/modules/applications/masterAccountAccess/details/types";
import masterAccountAccessApplicationsTypes from "@/store/modules/applications/masterAccountAccess/list/types";
import counterpartiesTypes from "@/store/modules/counterparties/types";
import accountsTypes from "@/store/modules/accounts/types";
import employeeJoinApplicationsTypes from "@/store/modules/applications/employeeJoin/list/types";
import employeeJoinApplicationDetailsTypes from "@/store/modules/applications/employeeJoin/details/types";
import loanScheduleTypes from "@/store/loan/modules/loanSchedule/types";
import agreementTypes from "@/store/loan/modules/loanSchedule/modules/agreement/types";
import overduesTypes from "@/store/loan/modules/loanSchedule/modules/overdues/types";
import paymentsTypes from "@/store/loan/modules/loanSchedule/modules/payments/types";
import scheduleItemsTypes from "@/store/loan/modules/loanSchedule/modules/scheduleItems/types";
import tranchesTypes from "@/store/loan/modules/loanSchedule/modules/tranches/types";
import legalEntityCounterpartyHeadTypes
	from "@/store/modules/counterparty/modules/legalEntityCounterparty/modules/legalEntityCounterpartyHead/types";
import legalEntityCounterpartyHeadEmbeddedSnilsFormModuleTypes
	from "@/store/modules/counterparty/modules/legalEntityCounterparty/modules/legalEntityCounterpartyHead/modules/snils/types";
import counterpartyEmployeeEmbeddedSnilsFormModuleTypes from "@/store/modules/counterpartyEmployee/modules/general/modules/snils/types";
import confirmPhoneTypes from "@/store/modules/confirmPhone/types";
import userTypes from "@/store/modules/user/types";
import agreementsAccruedInterestTypes from "@/store/loan/modules/agreementsAccruedInterest/types";
import accruedInterestTypes from "@/store/loan/modules/accruedInterest/types";
import overdueInterestTypes from "@/store/loan/modules/overdueInterest/types";
import overdueInterestListTypes from "@/store/loan/modules/overdueInterest/modules/overdueInterestIList/types";
import penaltyInterestListTypes from "@/store/loan/modules/overdueInterest/modules/penaltyInterestList/types";
import cashFlowTypes from "@/store/loan/modules/cashFlow/types";
import treasuryStatementTypes from "@/store/loan/modules/treasuryStatement/types";
import troubledBorrowerTypes from "@/store/loan/modules/troubledBorrower/types";
import mainInfoTypes from "@/store/loan/modules/troubledBorrower/modules/mainInfo/types";
import securityAgreementsTypes from "@/store/loan/modules/troubledBorrower/modules/securityAgreements/types";
import proceedingStatusTypes from "@/store/loan/modules/troubledBorrower/modules/productionStatus/types";
import documentsTypes from "@/store/bod/modules/documents/types";
import documentTypes from "@/store/bod/modules/document/types";
import requestsTypes from "@/store/bod/modules/requests/types";
import bodUserTypes from "@/store/bod/modules/user/types";
import bankAccountListTypes from "@/store/bar/modules/bankAccountsList/types";
import bankAccountTypes from "@/store/bar/modules/bankAccount/types";
import bankAccountApplicationsListTypes from "@/store/bar/modules/bankAccountApplicationsList/types";
import bankAccountApplicationTypes from "@/store/bar/modules/bankAccountApplication/types";
import upcomingPaymentsTypes from "@/store/loan/modules/upcomingPayments/types";
import agreementsOverduePaymentsTypes from "@/store/loan/modules/agreementsOverduePayments/types";
import agreementsAccruedPenaltiesTypes from "@/store/loan/modules/agreementsAccruedPenalties/types";
import barUserTypes from "@/store/bar/modules/user/types";
import bankAccountApplicationDocumentsTypes from "@/store/bar/modules/bankAccountApplication/modules/documents/types";
import bankAccountApplicationLegalDepartmentTypes from "@/store/bar/modules/bankAccountApplication/modules/legalDepartment/types";
import bankAccountApplicationMessengerTypes from "@/store/bar/modules/bankAccountApplication/modules/messenger/types";
import loanUserTypes from "@/store/loan/modules/user/types";
import tranchesBreadcrumbsTypes from "@/store/tranches/modules/breadcrumbs/types";
import tranchesUserTypes from "@/store/tranches/modules/user/types";
import tranchesTrancheApplicationTypes from "@/store/tranches/modules/application/types";
import kpisTypes from "@/store/kpi/modules/kpis/types";
import reportingTypes from "@/store/kpi/modules/reporting/types";
import kpiTypes from "@/store/kpi/modules/kpi/types";
import kpiMainInfoTypes from "@/store/kpi/modules/kpi/modules/mainInfo/types";
import kpiCriteriasInfoTypes from "@/store/kpi/modules/kpi/modules/criteriasInfo/types";
import employeesTypes from "@/store/hr/modules/employees/types";
import employeeTypes from "@/store/hr/modules/employee/types";
import employeeStandardAwarenessTypes from "@/store/hr/modules/employee/modules/standardAwareness/types";
import employeeFeedbackTypes from "@/store/hr/modules/employee/modules/feedback/types";
import employeeKpiTypes from "@/store/hr/modules/employee/modules/kpi/types";
import employeeDisciplineTypes from "@/store/hr/modules/employee/modules/discipline/types";
import employeeColleaguesTypes from "@/store/hr/modules/employee/modules/colleagues/types";
import employeeSubordinatesTypes from "@/store/hr/modules/employee/modules/subordinates/types";
import employeeExpectationsTypes from "@/store/hr/modules/employee/modules/expectations/types";
import employeePromisesTypes from "@/store/hr/modules/employee/modules/promises/types";
import employeeProfileTypes from "@/store/hr/modules/employee/modules/profile/types";
import delegacyTypes from "@/store/kpi/modules/delegacy/types";
import assignmentCompensationTypes from "@/store/loan/modules/assignmentCompensation/types";
import vacationPlanEmployeesTypes from "@/store/hr/modules/vacationPlanEmployees/types";
import vacationPlanHolidaysTypes from "@/store/hr/modules/vacationPlanCalendar/types";
import vacationPlansTypes from "@/store/hr/modules/vacationPlans/types";
import hrUserTypes from "@/store/hr/modules/user/types";
import hrVacationApplicationsTypes from "@/store/hr/modules/vacationApplications/types";
import hrVacationApplicationTypes from "@/store/hr/modules/vacationApplication/types";
import hrAbsencesTypes from "@/store/hr/modules/absences/types";

export const resolveDynamicNamespace = (namespace: string, value: any) => {
	return `${namespace}_${value}`;
};

class StoreManager {
	loan = {
		loanUser: {
			namespace: loanUserTypes.namespace
		},
		breadcrumbs: {
			namespace: loanBreadcrumbsTypes.namespace
		},
		loanSchedule: {
			namespace: loanScheduleTypes.namespace,
			agreement: {
				namespace: resolveNestedNamespace(loanScheduleTypes.namespace, agreementTypes.namespace)
			},
			overdues: {
				namespace: resolveNestedNamespace(loanScheduleTypes.namespace, overduesTypes.namespace)
			},
			payments: {
				namespace: resolveNestedNamespace(loanScheduleTypes.namespace, paymentsTypes.namespace)
			},
			scheduleItems: {
				namespace: resolveNestedNamespace(loanScheduleTypes.namespace, scheduleItemsTypes.namespace)
			},
			tranches: {
				namespace: resolveNestedNamespace(loanScheduleTypes.namespace, tranchesTypes.namespace)
			}
		},
		accruedInterest: {
			namespace: accruedInterestTypes.namespace
		},
		overdueInterest: {
			namespace: overdueInterestTypes.namespace,
			overdueInterestList: {
				namespace: resolveNestedNamespace(overdueInterestTypes.namespace, overdueInterestListTypes.namespace)
			},
			penaltyInterestList: {
				namespace: resolveNestedNamespace(overdueInterestTypes.namespace, penaltyInterestListTypes.namespace)
			}
		},
		interestAccrual: {
			namespace: agreementsAccruedInterestTypes.namespace
		},
		assignmentCompensation: {
			namespace: assignmentCompensationTypes.namespace
		},
		cashFlow: {
			namespace: cashFlowTypes.namespace
		},
		treasuryStatement: {
			namespace: treasuryStatementTypes.namespace
		},
		agreementsOverduePayments: {
			namespace: agreementsOverduePaymentsTypes.namespace
		},
		agreementsAccruedPenalties: {
			namespace: agreementsAccruedPenaltiesTypes.namespace
		},
		troubledBorrower: {
			namespace: troubledBorrowerTypes.namespace,
			mainInfo: {
				namespace: resolveNestedNamespace(troubledBorrowerTypes.namespace, mainInfoTypes.namespace)
			},
			productionStatus: {
				namespace: resolveNestedNamespace(troubledBorrowerTypes.namespace, proceedingStatusTypes.namespace)
			},
			securityAgreements: {
				namespace: resolveNestedNamespace(troubledBorrowerTypes.namespace, securityAgreementsTypes.namespace)
			}
		},
		troubledBorrowers: {
			namespace: troubledBorrowerTypes.namespace
		},
		upcomingPayments: {
			namespace: upcomingPaymentsTypes.namespace
		}
	};
	bod = {
		documents: {
			namespace: documentsTypes.namespace
		},
		document: {
			namespace: documentTypes.namespace
		},
		requests: {
			namespace: requestsTypes.namespace
		},
		user: {
			namespace: bodUserTypes.namespace
		}
	};
	kpi = {
		kpiRegistry: {
			namespace: kpisTypes.namespace
		},
		reporting: {
			namespace: reportingTypes.namespace
		},
		kpi: {
			namespace: kpiTypes.namespace,
			mainInfo: {
				namespace: kpiMainInfoTypes.namespace
			},
			criteriasInfo: {
				namespace: kpiCriteriasInfoTypes.namespace
			}
		},
		delegacy: {
			namespace: delegacyTypes.namespace
		}
	};
	hr = {
		user: {
			namespace: hrUserTypes.namespace
		},
		employees: {
			namespace: employeesTypes.namespace
		},
		employee: {
			namespace: employeeTypes.namespace,
			standardAwareness: {
				namespace: resolveNestedNamespace(employeeTypes.namespace, employeeStandardAwarenessTypes.namespace)
			},
			feedback: {
				namespace: resolveNestedNamespace(employeeTypes.namespace, employeeFeedbackTypes.namespace)
			},
			kpi: {
				namespace: resolveNestedNamespace(employeeTypes.namespace, employeeKpiTypes.namespace)
			},
			discipline: {
				namespace: resolveNestedNamespace(employeeTypes.namespace, employeeDisciplineTypes.namespace)
			},
			colleagues: {
				namespace: resolveNestedNamespace(employeeTypes.namespace, employeeColleaguesTypes.namespace)
			},
			subordinates: {
				namespace: resolveNestedNamespace(employeeTypes.namespace, employeeSubordinatesTypes.namespace)
			},
			expectations: {
				namespace: resolveNestedNamespace(employeeTypes.namespace, employeeExpectationsTypes.namespace)
			},
			promises: {
				namespace: resolveNestedNamespace(employeeTypes.namespace, employeePromisesTypes.namespace)
			},
			profile: {
				namespace: resolveNestedNamespace(employeeTypes.namespace, employeeProfileTypes.namespace)
			}
		},
		vacationPlans: {
			namespace: vacationPlansTypes.namespace
		},
		vacationPlanEmployees: {
			namespace: vacationPlanEmployeesTypes.namespace
		},
		vacationPlanCalendar: {
			namespace: vacationPlanHolidaysTypes.namespace
		},
		vacationApplication: {
			namespace: hrVacationApplicationTypes.namespace
		},
		vacationApplications: {
			namespace: hrVacationApplicationsTypes.namespace
		},
		absences: {
			namespace: hrAbsencesTypes.namespace
		}
	};
	bar = {
		breadcrumbs: {
			namespace: barBreadcrumbsTypes.namespace
		},
		bankAccountApplicationsList: {
			namespace: bankAccountApplicationsListTypes.namespace
		},
		bankAccountApplication: {
			namespace: bankAccountApplicationTypes.namespace
		},
		bankAccountApplicationDocuments: {
			namespace: bankAccountApplicationDocumentsTypes.namespace
		},
		bankAccountApplicationLegalDepartment: {
			namespace: bankAccountApplicationLegalDepartmentTypes.namespace
		},
		bankAccountApplicationMessenger: {
			namespace: bankAccountApplicationMessengerTypes.namespace
		},
		bankAccountsList: {
			namespace: bankAccountListTypes.namespace
		},
		barAccount: {
			namespace: bankAccountTypes.namespace
		},
		barUser: {
			namespace: barUserTypes.namespace
		}
	};
	tranches = {
		breadcrumbs: {
			namespace: tranchesBreadcrumbsTypes.namespace
		},
		tranchesUser: {
			namespace: tranchesUserTypes.namespace
		},
		trancheApplication: {
			namespace: tranchesTrancheApplicationTypes.namespace,
		}
	};
	breadcrumbs = {
		namespace: breadcrumbsTypes.namespace
	};
	counterparties = {
		namespace: counterpartiesTypes.namespace
	};
	masterAccountAccess = {
		namespace: masterAccountAccessTypes.namespace,
		selectCounterpartyStep: {
			namespace: resolveNestedNamespace(masterAccountAccessTypes.namespace, selectCounterpartyStepTypes.namespace)
		},
		contactInformationStep: {
			namespace: resolveNestedNamespace(masterAccountAccessTypes.namespace, contactInformationStepTypes.namespace)
		},
		documentsStep: {
			namespace: resolveNestedNamespace(masterAccountAccessTypes.namespace, documentsStepTypes.namespace)
		},
		signApplicationStep: {
			namespace: resolveNestedNamespace(masterAccountAccessTypes.namespace, signApplicationStepTypes.namespace)
		},
		finalStep: {
			namespace: resolveNestedNamespace(masterAccountAccessTypes.namespace, finalStepTypes.namespace)
		}
	};
	office = {
		namespace: officeTypes.namespace,
		general: {
			namespace: resolveNestedNamespace(officeTypes.namespace, officeGeneralTypes.namespace)
		},
		employees: {
			namespace: resolveNestedNamespace(officeTypes.namespace, officeEmployeesTypes.namespace)
		}
	};
	officeEmployee = {
		namespace: officeEmployeeTypes.namespace,
		general: {
			namespace: resolveNestedNamespace(officeEmployeeTypes.namespace, officeEmployeeGeneralTypes.namespace)
		},
		profile: {
			namespace: resolveNestedNamespace(officeEmployeeTypes.namespace, officeEmployeeProfileTypes.namespace)
		}
	};
	counterparty = {
		namespace: counterpartyTypes.namespace,
		foreignOrganization: {
			namespace: resolveNestedNamespace(counterpartyTypes.namespace, foreignOrganizationCounterpartyTypes.namespace)
		},
		legalPerson: {
			namespace: resolveNestedNamespace(counterpartyTypes.namespace, legalPersonCounterpartyTypes.namespace)
		},
		physicalPerson: {
			namespace: resolveNestedNamespace(counterpartyTypes.namespace, physicalPersonCounterpartyTypes.namespace)
		},
		legalEntity: {
			namespace: resolveNestedNamespace(counterpartyTypes.namespace, legalEntityCounterpartyTypes.namespace),
			heads: {
				resolveNamespace: (i: any) => resolveDynamicNamespace(resolveNestedNamespace(counterpartyTypes.namespace,
					legalEntityCounterpartyTypes.namespace, legalEntityCounterpartyHeadTypes.namespace), i),
				snils: {
					resolveNamespace: (i: any) => `${resolveNestedNamespace(this.counterparty.legalEntity.heads.resolveNamespace(i),
						legalEntityCounterpartyHeadEmbeddedSnilsFormModuleTypes.namespace)}`
				}
			}

		},
		employees: {
			namespace: resolveNestedNamespace(counterpartyTypes.namespace, counterpartyEmployeesTypes.namespace)
		},
		masterAccount: {
			namespace: resolveNestedNamespace(counterpartyTypes.namespace, counterpartyMasterAccountTypes.namespace),
			email: {
				namespace: resolveNestedNamespace(counterpartyTypes.namespace,
					counterpartyMasterAccountTypes.namespace,
					counterpartyMasterAccountEmailModule.namespace)
			}
		}
	};
	counterpartyEmployee = {
		namespace: resolveNestedNamespace(counterpartyEmployeeTypes.namespace),
		general: {
			namespace: resolveNestedNamespace(counterpartyEmployeeTypes.namespace, counterpartyEmployeeGeneralTypes.namespace),
			snils: {
				namespace: resolveNestedNamespace(counterpartyEmployeeTypes.namespace,
					counterpartyEmployeeGeneralTypes.namespace,
					counterpartyEmployeeEmbeddedSnilsFormModuleTypes.namespace)
			}
		},
		trusts: {
			namespace: resolveNestedNamespace(counterpartyEmployeeTypes.namespace, counterpartyEmployeeTrustsTypes.namespace)
		},
		trust: {
			namespace: resolveNestedNamespace(counterpartyEmployeeTypes.namespace, counterpartyEmployeeTrustTypes.namespace)
		},
		profile: {
			namespace: resolveNestedNamespace(counterpartyEmployeeTypes.namespace, counterpartyEmployeeProfileTypes.namespace)
		}
	};
	account = {
		namespace: accountTypes.namespace,
		general: {
			namespace: resolveNestedNamespace(accountTypes.namespace, accountGeneralTypes.namespace),
			email: {
				namespace: resolveNestedNamespace(accountTypes.namespace,
					accountGeneralTypes.namespace, accountGeneralEmailModule.namespace)
			},
			password: {
				namespace: resolveNestedNamespace(accountTypes.namespace,
					accountGeneralTypes.namespace, accountGeneralPasswordModule.namespace)
			}
		},
		profiles: {
			namespace: resolveNestedNamespace(accountTypes.namespace, accountProfilesTypes.namespace)
		}
	};
	applications = {
		masterAccountAccess: {
			details: {
				namespace: masterAccountAccessApplicationDetailsTypes.namespace
			},
			list: {
				namespace: masterAccountAccessApplicationsTypes.namespace
			}
		},
		employeeJoin: {
			details: {
				namespace: employeeJoinApplicationDetailsTypes.namespace
			},
			list: {
				namespace: employeeJoinApplicationsTypes.namespace
			}
		}
	};
	accounts = {
		namespace: accountsTypes.namespace
	};
	confirmPhone = {
		namespace: confirmPhoneTypes.namespace
	};
	user = {
		namespace: userTypes.namespace
	};
}

const storeManager = new StoreManager();

export default storeManager;
