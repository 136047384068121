import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";
import urls, { urlTemplateParts } from "@/api/config/urls";
import { ApiProjectInfo } from "@/api/tranches/types/apiProjectInfo";
import { ApiTrancheSchedule } from "@/api/tranches/types/apiTrancheSchedule";
import { FileTypes } from "@/constants/fileTypes";
import NotDefinedException from "@/exceptions/notDefinedException";
import contentDispositionParser from "content-disposition-parser";
import { ApiGetFileResponse } from "@/api/types/storage/apiGetFileResponse";
import { ApiTrancheScheduleDocument } from "@/api/tranches/types/apiTrancheScheduleDocument";
import { ApiTrancheCreateDocumentRequest } from "@/api/tranches/types/apiTrancheCreateDocumentRequest";
import { ApiUpdateTrancheScheduleRequestItem } from "@/api/tranches/types/apiUpdateTrancheScheduleRequestItem";
import { ApiTrancheScheduleHistory } from "@/api/tranches/types/apiTrancheScheduleHistory";
import { ApiConnectTrancheScheduleMessagesResponse } from "@/api/tranches/types/apiConnectTrancheScheduleMessagesResponse";
import { ApiGetFileByPostResponse } from "@/api/types/storage/apiGetFileByPostResponse";

export class TranchesController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getPermissions = async (): Promise<string[]> => {
		return await this.client.get<string[]>(urls.tranches.permissions.get);
	};
	
	getProjectInfo = async (applicationNumber: string): Promise<ApiProjectInfo> => {
		const url = urls.tranches.project.getProjectInfo.replace(urlTemplateParts.id, applicationNumber);
		return await this.client.get<ApiProjectInfo>(url, {});
	};
	
	getTrancheSchedule = async (applicationNumber: string): Promise<ApiTrancheSchedule> => {
		const url = urls.tranches.trancheSchedule.getTrancheSchedule.replace(urlTemplateParts.id, applicationNumber);
		return await this.client.get<ApiTrancheSchedule>(url, {});
	};
	
	updateTrancheSchedule = async (applicationNumber: string, tranches: ApiUpdateTrancheScheduleRequestItem[]): Promise<void> => {
		const url = urls.tranches.trancheSchedule.updateTrancheSchedule.replace(urlTemplateParts.id, applicationNumber);
		return await this.client.post(url, { tranches });
	};
	
	getTrancheScheduleHistory = async (applicationNumber: string) => {
		const url = urls.tranches.trancheSchedule.getTrancheScheduleHistory.replace(urlTemplateParts.id, applicationNumber);
		return await this.client.get<ApiTrancheScheduleHistory>(url, {});
	};
	
	sendTrancheForApproval = async (applicationNumber: string, trancheNumber: string): Promise<void> => {
		const url = urls.tranches.trancheSchedule.sendTrancheForApproval
						.replace(urlTemplateParts.id, applicationNumber)
						.replace(urlTemplateParts.secondId, trancheNumber);
		return await this.client.post(url, {});
	};
	
	confirmTranche = async (applicationNumber: string, trancheNumber: string): Promise<void> => {
		const url = urls.tranches.trancheSchedule.confirmTranche
						.replace(urlTemplateParts.id, applicationNumber)
						.replace(urlTemplateParts.secondId, trancheNumber);
		return await this.client.post(url, {});
	};
	
	rejectTranche = async (applicationNumber: string, trancheNumber: string, reasons: string): Promise<void> => {
		const url = urls.tranches.trancheSchedule.rejectTranche
						.replace(urlTemplateParts.id, applicationNumber)
						.replace(urlTemplateParts.secondId, trancheNumber);
		return await this.client.post(url, { reasons });
	};
	
	acceptTranche = async (applicationNumber: string, trancheNumber: string): Promise<void> => {
		const url = urls.tranches.trancheSchedule.acceptTranche
						.replace(urlTemplateParts.id, applicationNumber)
						.replace(urlTemplateParts.secondId, trancheNumber);
		return await this.client.post(url, {});
	};
	
	unacceptTranche = async (applicationNumber: string, trancheNumber: string): Promise<void> => {
		const url = urls.tranches.trancheSchedule.unacceptTranche
						.replace(urlTemplateParts.id, applicationNumber)
						.replace(urlTemplateParts.secondId, trancheNumber);
		return await this.client.post(url, {});
	};
	
	orderTranchePaymentApplication = async (applicationNumber: string, trancheNumber: string) => {
		const url = urls.tranches.trancheSchedule.orderTranchePaymentApplication
						.replace(urlTemplateParts.id, applicationNumber)
						.replace(urlTemplateParts.secondId, trancheNumber);
		return await this.client.post<ApiGetFileByPostResponse>(url, {}, { responseType: "blob" }, true);
	};
	
	generateTrancheSchedule = async (applicationNumber: string) => {
		const url = urls.tranches.trancheSchedule.generateInformationalLetter.replace(urlTemplateParts.id, applicationNumber);
		const { data, responseHeaders } = await this.client.get<ApiGetFileResponse>(url, { responseType: "blob" }, true);
		
		if(!data || !data.size || data?.type !== FileTypes.DOCX)
			throw new NotDefinedException(String(data));
		
		const { "content-disposition": contentDisposition } = responseHeaders;
		const { filename } = contentDispositionParser(contentDisposition);
		
		return { data, filename };
	};
	
	addTrancheDocument = async ({ applicationNumber, trancheNumber, tempFileId }: ApiTrancheCreateDocumentRequest) => {
		const url = urls.tranches.trancheSchedule.addTrancheDocument
						.replace(urlTemplateParts.id, String(applicationNumber))
						.replace(urlTemplateParts.secondId, String(trancheNumber))
						.replace(urlTemplateParts.thirdId, tempFileId);
		
		return await this.client.post<{ document: ApiTrancheScheduleDocument, issues: { issues: string[] } }>(url, {});
	};
	
	getTrancheDocument = async (applicationNumber: string, trancheNumber: string, documentNumber: string) => {
		const url = urls.tranches.trancheSchedule.getTrancheDocument
						.replace(urlTemplateParts.id, applicationNumber)
						.replace(urlTemplateParts.secondId, trancheNumber)
						.replace(urlTemplateParts.thirdId, documentNumber);
		
		const { data, responseHeaders } = await this.client.get<ApiGetFileResponse>(url, { responseType: "blob" }, true);
		
		const { filename } = contentDispositionParser(responseHeaders["content-disposition"]);
		
		return { data, filename };
	};
	
	deleteTrancheDocument = async (applicationNumber: string, trancheNumber: string, documentNumber: string) => {
		const url = urls.tranches.trancheSchedule.deleteTrancheDocument
						.replace(urlTemplateParts.id, applicationNumber)
						.replace(urlTemplateParts.secondId, trancheNumber)
						.replace(urlTemplateParts.thirdId, documentNumber);
		
		return await this.client.delete(url);
	};
	
	addTrancheAgreement = async (applicationNumber: string, trancheNumber: string, counterpartyAgreementId: string) => {
		const url = urls.tranches.trancheSchedule.addTrancheAgreement
						.replace(urlTemplateParts.id, applicationNumber)
						.replace(urlTemplateParts.secondId, trancheNumber)
						.replace(urlTemplateParts.thirdId, counterpartyAgreementId);
		
		return await this.client.post(url, {});
	};
	
	deleteTrancheAgreement = async (applicationNumber: string, trancheNumber: string, counterpartyAgreementId: string) => {
		const url = urls.tranches.trancheSchedule.deleteTrancheAgreement
						.replace(urlTemplateParts.id, applicationNumber)
						.replace(urlTemplateParts.secondId, trancheNumber)
						.replace(urlTemplateParts.thirdId, counterpartyAgreementId);
		
		return await this.client.delete(url);
	};
	
	connectTrancheScheduleMessages = async (applicationNumber: string) => {
		const url = urls.tranches.trancheSchedule.connectTrancheScheduleMessages.replace(urlTemplateParts.id, applicationNumber);
		
		return await this.client.post<ApiConnectTrancheScheduleMessagesResponse>(url, {});
	};
}
