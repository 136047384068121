import baseMixinTypes, { baseActionTypes } from "@/store/shared/base/types";
import routeTypes from "@/store/shared/route/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import { listingGetterTypes, listingActionTypes, listingMutationTypes } from "@/store/shared/listing/types";
import { pagingActionTypes, pagingMutationTypes } from "@/store/shared/paging/types";
import { sortingActionTypes, sortingMutationTypes } from "@/store/shared/sorting/types";
import { searchMixinTypes } from "@/store/shared/search/types";

export const namespace = "kpis";

export const getterTypes = {
	...listingGetterTypes,
	filteredItems: "filteredItems",
	availableUsers: "availableUsers",
	availableDepartments: "availableDepartments",
	isOneUserOnly: "isOneUserOnly"
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseActionTypes,
	...pagingActionTypes,
	...sortingActionTypes,
	...listingActionTypes,
	...searchMixinTypes.actionTypes,
	...routeTypes.actionTypes,
	downloadFile: "downloadFile",
	fetchUsers: "fetchUsers",
	fetchDepartments: "fetchDepartments",
	fetchPeriods: "fetchPeriods"
};

export const mutationTypes = {
	...baseMixinTypes.mutationTypes,
	...stateManipulationMixinTypes.mutationTypes,
	...sortingMutationTypes,
	...pagingMutationTypes,
	...listingMutationTypes,
	...searchMixinTypes.mutationTypes,
	...routeTypes.mutationTypes,
	SET_FILTER_YEAR: "SET_FILTER_YEAR",
	SET_FILTER_QUARTER: "SET_FILTER_QUARTER",
	SET_FILTER_STAFF_IDS: "SET_FILTER_STAFF_IDS",
	SET_FILTER_DEPARTMENT_ID: "SET_FILTER_DEPARTMENT_ID",
	SET_FILTER_KPI: "SET_FILTER_KPI",
	SET_FILTER_STATUS: "SET_FILTER_STATUS",
	RESET_FILTER: "RESET_FILTER",
	RESET_FRONT_FILTER: "RESET_FRONT_FILTER",
	SET_IS_USERS_LOADING: "SET_IS_USERS_LOADING",
	SET_IS_DEPARTMENTS_LOADING: "SET_IS_DEPARTMENTS_LOADING",
	SET_IS_PERIODS_LOADING: "SET_IS_PERIODS_LOADING",
	SET_IS_EXCEL_DOWNLOADING: "SET_IS_EXCEL_DOWNLOADING",
	SET_DEPARTMENTS: "SET_DEPARTMENTS",
	SET_PERIODS: "SET_PERIODS",
	SET_USERS: "SET_USERS",
};

const kpisTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default kpisTypes;
