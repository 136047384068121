import baseMixinTypes, { baseActionTypes } from "@/store/shared/base/types";
import routeTypes from "@/store/shared/route/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import { listingGetterTypes, listingActionTypes, listingMutationTypes } from "@/store/shared/listing/types";
import { pagingActionTypes, pagingMutationTypes } from "@/store/shared/paging/types";
import { sortingActionTypes, sortingMutationTypes } from "@/store/shared/sorting/types";
import { searchMixinTypes } from "@/store/shared/search/types";
import formTypes from "@/store/shared/form/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";

export const namespace = "hr-absences";

export const getterTypes = {
	...listingGetterTypes,
	...formTypes.getterTypes,
	...snapshotMixinTypes.getterTypes,
	currentUser: "currentUser",
	isAdmin: "isAdmin"
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseActionTypes,
	...pagingActionTypes,
	...sortingActionTypes,
	...listingActionTypes,
	...searchMixinTypes.actionTypes,
	...routeTypes.actionTypes,
	...formTypes.actionTypes,
	...snapshotMixinTypes.actionTypes,
	tryCreateAbsence: "tryCreateAbsence",
	fetchDepartments: "fetchDepartments",
	fetchEmployees: "fetchEmployees",
	fetchCalendar: "fetchCalendar",
};

export const mutationTypes = {
	...baseMixinTypes.mutationTypes,
	...stateManipulationMixinTypes.mutationTypes,
	...sortingMutationTypes,
	...pagingMutationTypes,
	...listingMutationTypes,
	...searchMixinTypes.mutationTypes,
	...routeTypes.mutationTypes,
	...formTypes.mutationTypes,
	...snapshotMixinTypes.mutationTypes,
	SET_FILTER_ABSENCE_TYPES: "SET_FILTER_ABSENCE_TYPES",
	SET_FILTER_DEPARTMENT_IDS: "SET_FILTER_DEPARTMENT_IDS",
	SET_FILTER_EMPLOYEE_IDS: "SET_FILTER_EMPLOYEE_IDS",
	SET_FILTER: "SET_FILTER",
	SET_IS_DEPARTMENTS_LOADING: "SET_IS_DEPARTMENTS_LOADING",
	SET_IS_EMPLOYEES_LOADING: "SET_IS_EMPLOYEES_LOADING",
	SET_DEPARTMENTS: "SET_DEPARTMENTS",
	SET_EMPLOYEES: "SET_EMPLOYEES",
	SET_CURRENT_OPENED_ABSENCE_ID: "SET_CURRENT_OPENED_ABSENCE_ID",
	RESET_NEW_ABSENCE: "RESET_NEW_ABSENCE",
	SET_NEW_ABSENCE_TYPE: "SET_NEW_ABSENCE_TYPE",
	SET_NEW_ABSENCE_START_DATE: "SET_NEW_ABSENCE_START_DATE",
	SET_NEW_ABSENCE_END_DATE: "SET_NEW_ABSENCE_END_DATE",
	SET_NEW_ABSENCE_EMPLOYEE_ID: "SET_NEW_ABSENCE_EMPLOYEE_ID",
	SET_NEW_ABSENCE_COMMENT: "SET_NEW_ABSENCE_COMMENT",
	ADD_LISTING_ITEM: "ADD_LISTING_ITEM",
	SET_IS_CALENDAR_LOADING: "SET_IS_CALENDAR_LOADING",
	SET_CALENDAR_DATES: "SET_CALENDAR_DATES",
	SET_YEAR: "SET_YEAR",
};

const hrAbsencesTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default hrAbsencesTypes;
