import urls, { urlTemplateParts } from "@/api/config/urls";
import { plainToInstance } from "class-transformer";
import { ApiCounterpartyEmployeeBase } from "@/api/types/counterparty/apiCounterpartyEmployee";
import { prepareUrl } from "@/utils/prepareUrlQuery";
import ApiLegalEntityCounterpartyPersisted from "@/api/types/counterparty/apiLegalEntityCounterpartyPersisted";
import HttpNotFoundException from "@/exceptions/httpNotFoundException";
import ApiLegalEntity from "@/api/types/legalEntity/apiLegalEntity";
import ApiLegalEntityHeadSnilsPersisted from "@/api/types/counterparty/snils/apiLegalEntityHeadSnilsPersisted";
import ApiLegalEntityHeadSnilsCreateRequest from "@/api/types/counterparty/snils/apiLegalEntityHeadSnilsCreateRequest";
import ApiLegalEntityHeadSnilsUpdateRequest from "@/api/types/counterparty/snils/apiLegalEntityHeadSnilsUpdateRequest";
import ApiLegalEntityCounterpartyHeadPersisted from "@/api/types/counterparty/apiLegalEntityCounterpartyHeadPersisted";
import ApiAccountProfilePersistedBase from "@/api/types/account/apiAccountProfilePersistedBase";
import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";
import { AuthorizationScopeType } from "@/types/authorization/authorizationScopeType";
import ApiLegalPersonIdentifiers from "@/api/types/counterparty/apiLegalPersonIdentifiers";
import ApiLegalEntityIdentifiers from "@/api/types/legalEntity/apiLegalEntityIdentifiers";

export class LegalEntityController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}

	updateLegalEntityCounterpartyEmployee = async (id: string,
		payload: ApiCounterpartyEmployeeBase,
		scope = AuthorizationScopeType.GLOBAL): Promise<void> => {
		const url = scope === AuthorizationScopeType.GLOBAL
			?
			urls.counterparty.legalEntity.employee.update.replace(urlTemplateParts.id,
				payload.counterpartyId).replace(urlTemplateParts.secondId, id)
			:
			urls.counterparty.legalEntity.employee.updateOwn.replace(urlTemplateParts.secondId, id);

		return await this.client.put<void>(url, payload);
	};

	createLegalEntityCounterpartyEmployee = async (counterpartyId: string,
		payload: ApiCounterpartyEmployeeBase,
		assignToAccount: boolean = true,
		scope = AuthorizationScopeType.GLOBAL): Promise<string> =>
	{
		const url = scope === AuthorizationScopeType.GLOBAL
			? prepareUrl(urls.counterparty.legalEntity.employee.create.replace(urlTemplateParts.id, counterpartyId),
				{ assignToAccount })
			: prepareUrl(urls.counterparty.legalEntity.employee.createOwn, { assignToAccount });


		return await this.client.post<string>(url, payload);
	};

	getLegalEntitySuggestion = async (inn?: string, ogrn?: string): Promise<ApiLegalEntity | null> => {
		try {
			let result = await this.client.get<{ counterparty: object }>(prepareUrl(urls.counterparty.legalEntity.getSuggestion, {
				inn, ogrn
			}));
			return plainToInstance(ApiLegalEntity, result);
		} catch (e) {
			if(e instanceof HttpNotFoundException)
				return null;
			else
				throw e;
		}
	};

	getLegalEntityCounterpartyByIdentifiers = async (inn: string,
		ogrn: string,
		kpp: string): Promise<ApiLegalEntityCounterpartyPersisted | null> => {
		try {
			let result = await this.client.get<{ counterparty: object }>(prepareUrl(urls.counterparty.legalEntity.getByIdentifiers, {
				inn, ogrn, kpp
			}));
			return plainToInstance(ApiLegalEntityCounterpartyPersisted, result);
		} catch (e) {
			if(e instanceof HttpNotFoundException)
				return null;
			else
				throw e;
		}
	};

	checkLegalEntityCounterpartyIsExists = async (inn: string, ogrn: string, kpp: string): Promise<boolean> => {
		const url = prepareUrl(urls.counterparty.legalEntity.checkIsExists, { inn, ogrn, kpp });

		const { exists } = await this.client.get<{ exists: boolean }>(url);

		return exists;
	};

	checkEmployeeExists = async (counterpartyIdentifiers: ApiLegalEntityIdentifiers, employeeEmail: string): Promise<boolean> => {
		const url = prepareUrl(urls.counterparty.legalEntity.checkEmployeeExists);

		const { exists } = await this.client.post<{ exists: boolean }>(url, {
			employerIdentifiers: counterpartyIdentifiers,
			employeeEmail
		});

		return exists;
	};

	getLegalEntityHeads = async (id: string, scope = AuthorizationScopeType.GLOBAL): Promise<ApiLegalEntityCounterpartyHeadPersisted[]> => {
		const url = scope === AuthorizationScopeType.GLOBAL
			? urls.counterparty.legalEntity.head.get.replace(urlTemplateParts.id, id)
			: urls.counterparty.legalEntity.head.getOwn;

		let result = await this.client.get<object[]>(url);

		return plainToInstance(ApiLegalEntityCounterpartyHeadPersisted, result);
	};

	getLegalEntityHeadSnils = async (legalEntityId: string,
		headId: string,
		scope = AuthorizationScopeType.GLOBAL): Promise<ApiLegalEntityHeadSnilsPersisted | null> => {
		try {
			const url = scope === AuthorizationScopeType.GLOBAL
				?
				urls.counterparty.legalEntity.head.snils.get.replace(urlTemplateParts.id, legalEntityId).replace(urlTemplateParts.secondId,
					headId)
				:
				urls.counterparty.legalEntity.head.snils.getOwn.replace(urlTemplateParts.secondId, headId);

			let result = await this.client.get<{ snils: object }>(url);

			return plainToInstance(ApiLegalEntityHeadSnilsPersisted, result);
		} catch (e) {
			if(e instanceof HttpNotFoundException)
				return null;
			else
				throw e;
		}
	};

	createLegalEntityHeadSnils = async (payload: ApiLegalEntityHeadSnilsCreateRequest,
		scope = AuthorizationScopeType.GLOBAL): Promise<{ id: string }> => {
		const url = scope === AuthorizationScopeType.GLOBAL
			?
			urls.counterparty.legalEntity.head.snils.create.replace(urlTemplateParts.id,
				payload.legalEntityId).replace(urlTemplateParts.secondId, payload.legalEntityHeadId)
			:
			urls.counterparty.legalEntity.head.snils.createOwn.replace(urlTemplateParts.secondId, payload.legalEntityHeadId);

		return await this.client.post<{ id: string }>(url, {
			temporaryFileId: payload.temporaryFileId
		});
	};

	updateLegalEntityHeadSnils = async (payload: ApiLegalEntityHeadSnilsUpdateRequest,
		scope = AuthorizationScopeType.GLOBAL): Promise<string> => {

		const url = scope === AuthorizationScopeType.GLOBAL
			?
			urls.counterparty.legalEntity.head.snils.update.replace(urlTemplateParts.id,
				payload.legalEntityId).replace(urlTemplateParts.secondId, payload.legalEntityHeadId)
			:
			urls.counterparty.legalEntity.head.snils.updateOwn.replace(urlTemplateParts.secondId, payload.legalEntityHeadId);

		return await this.client.put<string>(url, payload);
	};

	getLegalEntityProfile = async (legalEntityId: string): Promise<ApiAccountProfilePersistedBase | null> => {
		try {
			const url = urls.counterparty.legalEntity.profile.get.replace(urlTemplateParts.id, legalEntityId);

			let { profilePersisted } = await this.client.get<{ profilePersisted: object }>(url);

			return plainToInstance(ApiAccountProfilePersistedBase, profilePersisted);
		} catch (e) {
			if(e instanceof HttpNotFoundException)
				return null;
			else
				throw e;
		}
	};

	checkLegalEntityProfile = async (inn: string, ogrn: string, kpp: string): Promise<boolean> => {
		let { exists } = await this.client.post<{ exists: boolean }>(urls.counterparty.legalEntity.profile.check,
			{ inn, ogrn, kpp });

		return exists;
	};

	createLegalEntityProfile = async (legalEntityId: string, email: string): Promise<string> => {
		const url = urls.counterparty.legalEntity.profile.create.replace(urlTemplateParts.id, legalEntityId);

		return await this.client.post<string>(url, {
			email
		});
	};

	updateLegalEntityProfile = async (legalEntityId: string, email: string): Promise<string> => {
		const url = urls.counterparty.legalEntity.profile.update.replace(urlTemplateParts.id, legalEntityId);

		return await this.client.put<string>(url, {
			email
		});
	};

}
